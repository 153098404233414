@import '../../../../shared/assets/style/theme.scss';

.OnboardingLayer {
  position: fixed;
  pointer-events: none;
  z-index: 998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
}

.OnboardingLayerBox {
  position: absolute;
  pointer-events: auto;
}

.OnboardingLayerTooltip {
  position: absolute;
  min-width: 100px;
  max-width: 30%;
  padding: 0px;
  border-radius: 12px;
}

.OnboardingLayerTooltipInner {
  min-width: 350px;
  max-width: 15%;
  padding: 0px;
  border-radius: 8px;
}

.OnboardingLayerTooltipMessage {
  font-size: 18px;
  color: $common-1;
}

.OnboardingLayerImage {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.boxShaddow {
  box-shadow: -1px 1px 1000px 1000px rgba(0, 0, 0, 0.75);
}

.OnboardingLayerTooltipTriangle {
  position: absolute;
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
}

.OnboardingLayerTooltipRightTriangle {
  border-left-width: 0;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: $primary-30;
  border-bottom-color: transparent;
}

.OnboardingLayerTooltipBottomTriangle {
  border-top-width: 0;
  border-left-width: 10px;
  border-bottom-width: 10px;
  border-right-width: 10px;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: $primary-30;
}

.OnboardingLayerTooltipLeftTriangle {
  border-top-width: 10px;
  border-left-width: 10px;
  border-bottom-width: 10px;
  border-right-width: 0;
  border-left-color: $primary-30;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.OnboardingLayerTooltipTopTriangle {
  border-top-width: 10px;
  border-left-width: 10px;
  border-bottom-width: 0;
  border-right-width: 10px;
  border-left-color: transparent;
  border-top-color: $primary-30;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.FtueSkipHolder {
  position: absolute;
  height: 50px;
  width: 140px;
  left: 10px;
  top: 10px;
  z-index: 1;
  pointer-events: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

