@import '../../../../../shared/assets/style/theme.scss';

.RewardCenterOfferScrollContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.RewardCenterOffers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  justify-content: flex-start;
  padding: 0 0.3rem;
  border-bottom-left-radius: 0.1em;
  border-bottom-right-radius: 0.1em;
  box-shadow: inset 25px 0px 50px -50px $primary-28, inset -25px 0px 50px -50px $primary-28;

  .track-horizontal {
    background-color: $primary-24;
    width: 97%;
    left: 14px;
    bottom: 2px;
    border-radius: 8px;
    height: 10px !important;
    position: absolute;

    .thumb-horizontal {
      background-color: $primary-25;
      height: 10px !important;
      cursor: pointer;
      border-radius: 8px;
      position: relative;
    }
  }
}

.RewardCenterOffer {
  margin: 1em 2em 1em;
  border-radius: 1.2em;
  cursor: pointer;
  height: 24em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 270px;
  max-width: 270px;
  flex-grow: 1;

  &:hover {
    border-color: $secondary-26;
  }
}

.RewardCenterThumbnailContainer {
  flex: 1;
  position: relative;
}

.RewardCenterThumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.RewardCenterOfferCost {
  position: relative;
  padding: 0.5em 0;
  text-align: center;
  flex: 0;
  top: -25.5%;
}

.RewardCenterOfferName {
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  text-align: center;
  padding: 0.6em 0;
  flex: 0;
  position: relative;
  top: -17%;
  font-size: 1.5em;
  font-weight: 600;

  &.category {
    background: linear-gradient(to bottom, $secondary-15 30%, $secondary-27 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &.ieHack {
      color: $secondary-24;
      background: none;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .RewardCenterOffer {
    height: 18em;
  }
  .RewardCenterOfferName {
    padding: 0;
  }
}

