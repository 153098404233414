@import '../../../../shared/assets/style/theme.scss';

.TrackingURLContainer::-webkit-scrollbar {
  display: none;
}
.TrackingURLContainer {
  background-position: initial;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-top: 2em;
  .contentContainer {
    width: 60%;
  }
  .contentContainer p {
    font-size: 1.5rem;
  }
  .linkIconHolder {
    flex-direction: row;
  }
  .mainInmage {
    width: 90%;
  }

  .innderDiv img {
    width: 80%;
  }

  @media only screen and (max-width: 1000px) {
    background-position: center;
    .contentContainer {
      width: 100%;
    }
    .linkIconHolder {
      flex-direction: column;
      .innderDiv {
        margin-top: 1.5rem;
      }
    }
  }

  @media screen and (orientation: portrait) and (max-width: 460px) {
    .linkIconHolder {
      flex-direction: column;
      .innderDiv {
        margin-top: 2rem;
      }
    }
    .contentContainer {
      width: 100%;
    }
    .contentContainer div {
      margin-top: 1em;
    }
    .contentContainer p {
      font-size: 4.5rem;
    }
    .innderDiv img {
      width: 45em;
    }
    .mainInmage {
      width: 90%;
    }
  }
}

.AppStyle {
  text-align: center;
  color: $common-1;
  width: 100vw;
  height: 100vh;
  font-weight: 400;
  font-style: normal;
  user-select: none;
}

