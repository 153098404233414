.gtp-modal-favouriteListDialogue {

  .modal-content {
    padding: 0.1rem 2rem 0.1rem 2rem;
    border-radius: 2rem;
    width: 150%;
    height: 80%;
    text-align: center;
    position:absolute;
    right: -170px;
    }

    .helperBackground {
      height: 100%; 
      width: 100%;
    }

    .headerStyle {
      height: 30%; 
      width: 100%;
      text-align: center; 
      position: absolute;
      margin-left: -15px;
      top: 30px; 
    }

    .Live-Panels-Container {
      position: relative;
      width: 98%;
      height: 100%;
      margin-top: 140px;
      margin-left: 5px;
    }

    .live-panel-grid {
      position: relative;
      display: inline-block;
      flex: 1;
      width: 100%;
      height: 98%;
      text-align: left;
      margin-left: 70px;
      $tileSize: 200px;
    
      display: grid;
      grid-template-columns: repeat(auto-fill, $tileSize);
      grid-gap: 50px;
      grid-auto-rows: $tileSize;
      grid-auto-flow: dense;
    
      /** Auto-generate css for each live panel shapes (width, height) */
      @mixin live-panel-spans {
        $list: (1, 1) (2, 2) (1, 2) (2, 1) (2, 3);
        @each $shape in $list {
          .span-#{nth($shape, 1)}-#{nth($shape, 2)} {
            grid-column-end: span #{nth($shape, 1)};
            grid-row-end: span #{nth($shape, 2)};
          }
        }
      }
    
      @include live-panel-spans();
    }
}