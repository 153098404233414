@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-purchase-succeeded {
  width: 45vw;

  .ModalBackground:before {
    content: none;
  }

  .gradientText {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
  }

  .SuccessMessageContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .congratulationsImage {
      width: 100%;
      height: 70%,
    }

    .subtitle {
      position: relative;
      font-weight: 500;
      margin-bottom: 0;
      padding: 2.5rem 4rem 1.5rem 4rem;
      text-align: center;
      font-size: 1.4rem;
    }

    .title {
      @extend .gradientText;
      font-size: 2rem;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      margin-top: 0.5em;
    }

    .successHeader {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1;
    }

    .messageContainer {
      width: 100%;
      text-align: center;
      z-index: 1;
    }

    .message {
      font-size: 1.2rem;
    }

    .button {
      width: 30%;
      margin: 0.5em 0 0.8em 0;
      cursor: pointer;

      &.ieHack {
        height: 14%;
      }
    }

    .successButton {
      position: relative;
      text-align: center;
      cursor: pointer;
      pointer-events: auto;

      .successButtonLabel {
        text-transform: uppercase;
        position: absolute;
        font-size: 1.4rem;
        text-align: center;
        text-transform: uppercase;
        top: 50%;
        left: 50%;
        width: 100%;
        padding: 1em;
        transform: translate(-50%, -50%);
      }
    }
  }

  .successButtons {
    display: flex;
    justify-content: center;
    padding-bottom: 0.8rem;
  }

  .successButton {
    cursor: pointer;
    pointer-events: auto;
  }
}
