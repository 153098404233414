.custom-scrollbar-missions .os-scrollbar {
  padding: 0;
  margin-left: 16px;
  margin-bottom: 20px;
  margin-right: 40px;
}

.custom-scrollbar-missions .os-scrollbar-track {
  background-color: #ecc43470;
}

.custom-scrollbar-missions .os-scrollbar-handle {
  background-color: #ecc434;
}

.custom-scrollbar-missions-mobile .os-scrollbar {
  margin-bottom: 52px;
}

