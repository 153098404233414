.custom-scrollbar-events .os-scrollbar {
  padding: 0 14px;
}

.custom-scrollbar-events .os-scrollbar-track {
  background-color: #ecc43470;
}

.custom-scrollbar-events .os-scrollbar-handle {
  background-color: #ecc434;
}

