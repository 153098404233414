//Watch_and_Earn ads modal
@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-video-ad {
  width: 50vw;
  height: 100%;
  max-width: none;
  min-height: 0 !important;

  .modal-content {
    height: 50vh;
    border-radius: 2rem;
  }

  .modal-body {
    padding: 0;
    height: 100%;
    display: flex;
  }
}

.videoAdsContainer {
  padding-left: 0.4em;
  padding-right: 0.3em;
  border-radius: 1em;
  width: 100%;
  height: 99.1%;
  overflow: hidden;
  position: relative;

  .videoWrapper {
    position: relative;
    width: 100%;
    height: 95%;
    top: 0.3em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: -1;

    .player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1.4rem 1.4rem;
      overflow: hidden;
      pointer-events: none;
      z-index: -1;
    }
  }

  .progressArea {
    width: 100%;
    height: 6%;
    background: $common-3;
    margin-top: -0.3em;
    border-radius: 0 0 0.8em 0.8em;

    .timeArea {
      display: inline-table;
      width: 11%;
      height: 100%;
      background: $common-4;
      vertical-align: top;
      border-radius: 0 0 0 0.9em;
      margin-left: -0.01em;

      .videoTime {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        font-size: calc(10px + (16 - 10) * ((100vw - 1200px) / (1900 - 1200)));
        color: $common-1;
        font-weight: 400;
      }
    }

    .barArea {
      display: inline-table;
      width: 89%;
      height: 100%;
      vertical-align: top;
      position: relative;

      .backerContainer {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        .barBacker {
          width: 94%;
          height: 27%;
          background: $common-5;
          margin: 0 auto;
          border-radius: 2em;

          .barFill {
            border-radius: 2em;
            background: $secondary-33;
            height: 100%;
          }
        }
      }
    }
  }
  .warningOverlay {
    background: $common-31;
    width: 99%;
    position: absolute;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    margin-left: 0.3em;
    margin-top: 0.3em;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .warning {
    max-width: 500px;
    height: 260px;
    text-align: center;
    padding: 0 0.5em;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0.5em;
    width: 100%;
  }

  .warningHeader {
    height: '20%';
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1em;
    font-weight: 600;
  }

  .warningMessage {
    height: '25%';
    text-align: center;
    font-size: 1.1em;

    &.ieHack {
      margin: 0 0.5em 1em 0.5em;
      width: 100%;
    }
  }

  .warningButton {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: auto;
    margin: 1em 0 0 0;

    &.ieHack {
      justify-content: space-around;
    }
  }

  .closeButton,
  .keepWatchingButton {
    position: relative;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;
    align-self: center;
    height: 3em;
    width: 11em;

    .closeButtonLabel,
    .keepWatchingButtonLabel {
      position: absolute;
      font-size: 1em;
      font-weight: 600;
      text-shadow: 2px 2px 4px $common-0;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 1em;
      transform: translate(-50%, -50%);
    }

    .closeButtonLabel {
      text-shadow:
        1px 1px 0px $secondary-34,
        1px -1px 0px $secondary-34,
        -1px 1px 0px $secondary-34,
        -1px -1px 0px $secondary-34;
    }

    .keepWatchingButtonLabel {
      text-shadow:
        1px 1px 0px $secondary-0,
        1px -1px 0px $secondary-0,
        -1px 1px 0px $secondary-0,
        -1px -1px 0px $secondary-0;
    }
  }
}

//Watch_and_Earn Congrats modal
.gtp-modal-watchAndEarn-Congrats {
  width: 30%;
  height: 50vh;

  .modal-content {
    height: 50vh;
    border-radius: 2rem;
  }

  .modal-body {
    height: 100%;
    padding: 0.25rem 0.25rem 0 0.25rem;
  }
}

.watchAndEarnCongratsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94%;

  .watchAndEarnPopupIcon {
    position: relative;
    width: 85%;
    height: 48%;
    bottom: 2em;
  }
}

.watchAndEarnButton {
  position: relative;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  align-self: center;
  height: 3em;
  width: 11em;

  .watchAndEarnButtonLabel {
    position: absolute;
    font-size: 1.4rem;
    font-weight: 300;
    text-shadow:
      1px 1px 0px $secondary-10,
      1px -1px 0px $secondary-10,
      -1px 1px 0px $secondary-10,
      -1px -1px 0px $secondary-10;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 1em;
    transform: translate(-50%, -50%);
  }
}

//Watch_and_Earn Info modal
.gtp-modal-watchAndEarn-info {
  width: 40%;

  .modal-content {
    border-radius: 2rem;
    height: 50vh;
  }

  .modal-body {
    height: 100%;
    padding: 0.25rem 0.25rem 0 0.25rem;
  }
}

.WatchAndEarnInfoTimer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  background: black;
  width: 20em;
  height: 5em;
  justify-content: center;
  align-items: center;
  display: flex;
}

.watchAndEarnInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  &.ieHack {
    height: 100%;
  }

  .watchAndEarnPopupIcon {
    position: relative;
    width: 50%;
    height: 45%;
    bottom: 2em;
  }

  .timerContainer {
    position: relative;
    text-align: center;
    width: 18em;
    height: 4em;
    margin-bottom: 1.5em;

    .timeRemaining {
      position: absolute;
      font-size: 2em;
      top: 53%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

:root[data-pwa-mode='mobile-standalone'],
:root[data-pwa-mode='mobile-browser'],
:root[data-pwa-mode='tablet-standalone'],
:root[data-pwa-mode='tablet-browser'] {
  .watchAndEarnCongratsContainer {
    .watchAndEarnPopupIcon {
      bottom: 0;
    }
  }
}

