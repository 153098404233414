.gtp-modal-lto {
  width: 59vw !important;

  .modal-content {
    padding-top: 60.25% !important;
  }

  .ModalBackground {
    height: 96%;
  }

  .ModalBackground:before {
    content: none;
  }

  .LTOWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 81%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .mainContainer {
      display: flex;
      flex-direction: column;
      width: 70%;
      margin-bottom: 0.5%;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      font-weight: 600;
      height: 25%;
    }

    .bannerContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
    }
  }

  .buttonContainer {
    cursor: pointer;
    pointer-events: auto;
    position: absolute;
    height: 12%;
    top: 82%;
  }
}
