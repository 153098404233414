
  .levelUpTitle {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 2%;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  .button {
    bottom: 5%;
    cursor: pointer;
    pointer-events: auto;
    text-align: center;
    width: 25%;
  }

  .buttonImage {
    width: 100%;
    height: 100%;
  }