@import '../../../../shared/assets/style/theme.scss';

.gradientText {
  @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
  text-shadow: 0px 1px 2px $common-26;
}

.starGradientText {
  @include linearGradientText($terms-background-color, $primary-20 20%, $common-1 100%);
  text-shadow: 0px 1px 2px $common-26;
}

.disabledGradientText {
  @include linearGradientText($common-0, $primary-20 20%, $common-32 100%);
  text-shadow: 0px 1px 2px $common-26;
}

.settings-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
  min-height: 24rem;

  .form-scrollContainer {
    overflow: hidden !important;
  }

  .pointer {
    cursor: pointer;
  }

  .actions-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30%;
    font-size: 1.45rem;
    font-weight: 600;

    .settings-text {
      @extend .gradientText;
      padding: 0;
      margin: 0;
    }

    .settings-text-disabled {
      @extend .disabledGradientText;
      padding: 0;
      &:before {
        content: attr(data-label);
        text-transform: capitalize;
      }
    }

    .forward-arrow-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      z-index: 100;
    }
  }

  .custom-switch input:checked+.slider {
    background-color: #3bec09
  }

  .custom-switch .slider {
    background-color: #9b9b9b;
  }

  .custom-switch .slider::before {
    box-shadow: 0px 0px 1px 1.5px rgba(0, 0, 0, 0.35);
  }

  .account-title {
    @extend .starGradientText;
    font-size: 1.3rem;
    font-weight: 400;
    &:before {
      content: attr(data-label);
      text-transform: capitalize;
    }
  }

  .account-value {
    @extend .gradientText;
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    padding: 0;
    margin: 0;
    &:before {
      content: attr(data-label);
      text-transform: lowercase;
    }
  }

  .password-title {
    @extend .gradientText;
    font-size: 1.3rem;
    font-weight: 400;
    &:before {
      content: attr(data-label);
      text-transform: capitalize;
    }
  }

  .password-footer {
    font-size: 0.9rem;
    font-weight: 400;
    color: $primary-20;
  }

  .switch-enabled {
    @extend .gradientText;
    font-size: 1.2rem;
    font-weight: 400;
    &:before {
      content: attr(data-label);
    }
  }

  .switch-disabled {
    @extend .disabledGradientText;
    font-size: 1.2rem;
    font-weight: 400;
    color: $common-34;
    &:before {
      content: attr(data-label);
    }
  }

  .bottom-divider {
    width: 100%;
    height: 2rem;
  }

  .supportForm {
    height: 100%;
  }

  .categorySelectExpanded {
    .messageCategoryContainer {
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .messageSubjectContainer {
      margin-top: 3.3rem;
    }
  }

  .action-column {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    top: -1.3vmin;
  }

  .version-container {
    font-size: 10px;
    color: gray;
    text-align: center;
    top: 1.5vmin;
    position: relative;
  }

  .legalText {
    flex-basis: 100%;
    background-color: $terms-background-color;
    color: $common-1;
    text-align: left;
    padding: 1rem;
    white-space: pre-wrap;
    border-radius: 5px;
  }

  .popup-content-container {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 9vmin;
  }

  .sent-message-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2vmin;
  }
  .sent-message-image {
    width: 9.5rem;
    height: 6.5rem;
  }

  .popup-message-text {
    font-size: 1.5rem;
    font-weight: 500;
    color: $common-1;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .done-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0.8rem;
    left: 1rem;
    right: 1rem;

    .continue-button-background-image {
      width: 200px;
      height: 65px;
    }
    .done-button-background-image {
      width: 180px;
      height: 58px;
    }
    .done-button-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .done-button-text {
      @extend .gradientText;
      font-size: 1.7rem;
      font-weight: 600;
    }

    .loguot-button-text {
      @extend .gradientText;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  .cheat-menu-button {
    position: absolute;
    background: none;
    border: none;
    right: 5px;
    bottom: 5px;
    color: $common-1;
    text-decoration: underline;
    font-weight: 600;
  }

  .env-switch {
    position: absolute;
    background: none;
    border: none;
    top: 48px;
    right: 5px;
    bottom: 0px;
    color: $common-1;
    text-decoration: underline;
    font-weight: 600;
  }
}

.edit-button-container {
  .edit-button-background-image {
    width: 120px;
    height: 40px;
    cursor: pointer;
  }
  .edit-button-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-button-text {
    @extend .gradientText;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.settings-header {
  .header-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vmin;
  }

  .back-arrow-container {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    height: 2.3rem;
    width: 1.2rem;
    top: 1rem;
    left: 2rem;
  }

  .settings-title {
    @extend .gradientText;
    font-size: 1.9rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    &.hri {
      margin-top: 5px;
    }
  }

  .help {
    font-size: 15px;
    font-weight: 600;
    color: $common-1;
    padding: 0;
    margin: 0;
  }
}

