.linkAccountContainer, .cardContainer {
  display: flex;
  flex-direction: column;

  .linkAccountTitle, .cardTitle {
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin: 0.2em auto;
  }

  .availableCards {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 1rem;

    .bronze, .silver, .gold, .platinum, .diamond {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  .linkAccountDescription {
    margin: 1rem 2rem;
    font-size: 1.1rem;
    padding: 0.5rem;
  }

  .linkAccountButton {
    position: relative;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;

    .linkAccountButtonLabel {
      position: absolute;
      font-size: 1.4rem;
      text-align: center;
      top: 50%;
      left: 50%;
      width: 100%;
      padding: 1em;
      transform: translate(-50%, -50%);
    }
  }

  .cardIcon {
    .platinum_card {
      display: inline;
    }
    
    .cardTitle {
      display: inline-block;
      width: 80%;
    }
  }

  .cardBenefits {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    font-size: 1.1rem;
    padding: 0.5rem;
  }

  .detailsLink {
    text-align: center;
    font-size: 1.1rem;
  }
}