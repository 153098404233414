@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 100;
  src: local('OpenSans'), local('OpenSans-Thin'), url('./fonts/OpenSans-Light.woff2') format('woff2'),
    url('./fonts/OpenSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 100;
  src: local('OpenSans'), local('OpenSans-ThinItalic'), url('./fonts/OpenSans-LightItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 200;
  src: local('OpenSans'), local('OpenSans-ExtraLight'), url('./fonts/OpenSans-Light.woff2') format('woff2'),
    url('./fonts/OpenSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 200;
  src: local('OpenSans'), local('OpenSans-ExtraLightItalic'), url('./fonts/OpenSans-LightItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 300;
  src: local('OpenSans'), local('OpenSans-Light'), url('./fonts/OpenSans-Light.woff2') format('woff2'),
    url('./fonts/OpenSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 300;
  src: local('OpenSans'), local('OpenSans-LightItalic'), url('./fonts/OpenSans-LightItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans'), local('OpenSans-Book'), url('./fonts/OpenSans-Regular.woff2') format('woff2'),
    url('./fonts/OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 400;
  src: local('OpenSans'), local('OpenSans-BookItalic'), url('./fonts/OpenSans-Italic.woff2') format('woff2'),
    url('./fonts/OpenSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 500;
  src: local('OpenSans'), local('OpenSans-Medium'), url('./fonts/OpenSans-Medium.woff2') format('woff2'),
    url('./fonts/OpenSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 500;
  src: local('OpenSans'), local('OpenSans-MediumItalic'), url('./fonts/OpenSans-MediumItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 600;
  src: local('OpenSans'), local('OpenSans-SemiBold'), url('./fonts/OpenSans-SemiBold.woff2') format('woff2'),
    url('./fonts/OpenSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 600;
  src: local('OpenSans'), local('OpenSans-SemiBoldItalic'), url('./fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 700;
  src: local('OpenSans'), local('OpenSans-Bold'), url('./fonts/OpenSans-Bold.woff2') format('woff2'),
    url('./fonts/OpenSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 700;
  src: local('OpenSans'), local('OpenSans-BoldItalic'), url('./fonts/OpenSans-BoldItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 800;
  src: local('OpenSans'), local('OpenSans-ExtraBold'), url('./fonts/OpenSans-ExtraBold.woff2') format('woff2'),
    url('./fonts/OpenSans-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 800;
  src: local('OpenSans'), local('OpenSans-ExtraBoldItalic'),
    url('./fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: normal;
  font-weight: 900;
  src: local('OpenSans'), local('OpenSans-Black'), url('./fonts/OpenSans-ExtraBold.woff2') format('woff2'),
    url('./fonts/OpenSans-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'CustomClientFont';
  font-style: italic;
  font-weight: 900;
  src: local('OpenSans'), local('OpenSans-BlackItalic'), url('./fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'dripicons-v2';
  src: url('./fonts/dripicons/dripicons-v2.eot');
  src: url('./fonts/dripicons/dripicons-v2.eot?#iefix') format('embedded-opentype'),
    url('./fonts/dripicons/dripicons-v2.woff') format('woff'),
    url('./fonts/dripicons/dripicons-v2.ttf') format('truetype'),
    url('./fonts/dripicons/dripicons-v2.svg#dripicons-v2') format('svg');
  font-weight: normal;
  font-style: normal;
}

