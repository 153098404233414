@import '../../../../shared/assets/style/theme.scss';

.gradientText {
  @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
  text-shadow: 0px 1px 2px $common-26;
}

.adBlocker-header {
  .header-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vmin;
  }

  .adBlocker-title {
    @extend .gradientText;
    font-size: 1.9rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
  }
}

.adBlocker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: 14rem;
  font-size: 1.25rem;
}