.gtp-modal-news {
  max-width: 66%;
}

.gtp-modal-news-small {
  max-width: 50%;
  margin-top: 60px;
  @media (min-height: 670px) {
    margin-top: 50px;
  }
}

.newsBody {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'space-between';
  align-items: 'center';
  text-align: 'center';
}

