@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-finish-purchase {
  color: $common-1;
  width: 45vw;
  max-width: none;

  .ModalBackground:before {
    content: none;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: center;

    font-size: 4vmin;
    font-weight: 400;
    margin-bottom: 3vmin;
  }

  .gradientText {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
  }

  .finishPurchaseContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 8rem;
    font-size: 3vmin;
  }
}

:root[data-pwa-mode='mobile-standalone'],
:root[data-pwa-mode='mobile-browser'] {
  .gtp-modal-finish-purchase {
    min-width: 800px;
  }
}
:root[data-pwa-mode='tablet-standalone'],
:root[data-pwa-mode='tablet-browser'] {
  .gtp-modal-finish-purchase {
    min-width: 90%;
  }
}

