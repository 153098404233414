@import '../../../../../../../shared/assets/style/theme.scss';

.custom-scrollbar-message-list {
  width: 95%;
  height: 100%;
}

.custom-scrollbar-message-list .os-scrollbar-track {
  background-color: $scrollbar-1;
  box-shadow: inset 0 0 4px 2px rgba(0, 0, 0, 0.5);
  width: 8px;
}

.custom-scrollbar-message-list .os-scrollbar-vertical .os-scrollbar-handle {
  background-color: $scrollbar-2;
  border-radius: 10px;
  width: 7px;
  margin-right: 1px;
}

.custom-scrollbar-message-list .os-scrollbar {
  margin: 22px 0;
  padding: 0;
}

.custom-scrollbar-message-list .os-scrollbar {
  height: 92%;
}

[data-overlayscrollbars-viewport] {
  display: flex;
  flex-direction: column;
}

