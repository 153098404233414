@import '../../../../../shared/assets/style/theme.scss';

.modal-body-reward-center {
  width: 80%;
  max-width: 1100px;
  height: 58vh;
  margin-top: 72px;

  @media (min-height: 690px) {
    max-height: 66vh;
    margin-top: 0;
  }
}

.modal-body-reward-center-Small {
  width: 80%;
  max-width: 1000px;
  height: 54vh;
  margin-top: 110px;
}

.RewardCenterProfileDetail {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 46vh;
  min-height: 400px;
}
.RewardCenterProfileItems {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  width: 100%;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  padding-bottom: 50px;
}

.RewardCenterProfileItems h4 {
  height: 70px;
}
.RewardCenterProfileDetailBox {
  width: 300px;
  height: 270px;
  text-align: center;
  margin: 0 2rem;

  p {
    margin: 0;
    font-size: 32px;
  }
  h6 {
    color: $common-5;
  }
}

.registerBtn {
  text-shadow:
    -1px -1px 0 $success-3,
    1px -1px 0 $success-3,
    -1px 1px 0 $success-3,
    1px 1px 0 $success-3;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  width: 230px;
  height: 75px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}

.RewardCenterProfileDetailBoxInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 92%;
  padding: 10px 15px;
}

.RewardCenterUnlinkedProfileDetailBox {
  width: 370px;
  height: 100%;
  max-height: 310px;
  text-align: center;
  margin: 0 2rem;

  h4 {
    font-weight: bold;
  }
}

@media only screen and (max-width: 1200px) {
  .RewardCenterProfileDetailBox {
    width: 240px;
    height: 200px;
  }
  .RewardCenterUnlinkedProfileDetailBox {
    width: 320px;
  }
  .registerBtn {
    width: 190px;
    height: 65px;
  }
}

.unlinkedProfileContainer {
  display: flex;
  width: 90%;
  height: 80%;
  margin: 10px;
  justify-content: space-around;
  align-items: center;
}

.RewardCenterLinkAccountHolder {
  justify-content: center;
  align-items: center;
  width: 70%;
  cursor: pointer;
}

