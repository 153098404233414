@import '../../../../../shared/assets/style/theme.scss';

.track-vertical {
  background-color: $primary-24;
  width: 0.5rem;
  top: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  position: absolute;

  .thumb-vertical {
    background-color: $primary-25;
    width: 0.5rem;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
  }
}

.OfferDetail {
  padding: 0 3em;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.OfferDetailName {
  text-transform: uppercase;
  font-weight: 600;
  font-size: calc(14px + (26 - 14) * ((100vw - 800px) / (1600 - 800)));
  text-shadow: '2px 2px 4px $common-0';
}

.OfferDetailSubtitle {
  color: $common-12;
  font-weight: 600;
  font-size: calc(12px + (18 - 12) * ((100vw - 800px) / (1600 - 800)));
  background: linear-gradient(30deg, $secondary-24, $secondary-15, $secondary-11);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.ieHack {
    color: $secondary-24;
    background: none;
  }
}

.OfferDetailAction {
  .Disabled {
    filter: grayscale(1);
  }

  .Enabled {
    filter: grayscale(0);
  }

  .button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    transform: translate(-50%, -50%);

    .rewardCenterGradientText {
      &.Disabled {
        text-shadow: none;
      }

      &.Enabled {
        text-shadow:
          1px 1px 0px $secondary-1,
          1px -1px 0px $secondary-1,
          -1px 1px 0px $secondary-1,
          -1px -1px 0px $secondary-1;
      }
    }
  }
}

.OfferDetailDescription {
  font-size: calc(12px + (18 - 12) * ((100vw - 800px) / (1600 - 800)));
  white-space: pre-wrap;
}

.OfferDetailTerm {
  font-size: calc(12px + (18 - 12) * ((100vw - 800px) / (1600 - 800)));
  white-space: pre-wrap;
}

.OfferDetailTermTitle {
  margin-top: 20px;
  font-weight: bold;
  font-size: calc(14px + (18 - 12) * ((100vw - 800px) / (1600 - 800)));
}

.OfferDetailDivider {
  margin: 1em 0;
  width: 100%;
  height: 2px;
}

.OfferDetailThumbnailContainer {
  min-width: 250px;
  max-width: 270px;
  max-height: 400px;
  flex-grow: 1;
  height: 35vh;
  border-radius: 1.2em;
  position: relative;

  @media only screen and (max-height: 619px) {
    height: 26vh;
    min-width: 120px;
  }

  @media only screen and (min-height: 620px) and (max-height: 719px) {
    height: 31vh;
    min-width: 160px;
  }

  @media only screen and (min-height:720px) and (max-height: 920px) {
    min-width: 200px;
  }

  .carousel {
    &.slide {
      height: 100%;

      .carousel-indicators {
        bottom: -4em;
        opacity: 0.9;
      }

      .carousel-indicators li {
        background-color: $secondary-41;
        border: 1.5px solid $secondary-42;
        opacity: 1;

        &.active {
          background-color: $secondary-12;
          border: 1.5px solid $secondary-13;
          box-shadow: 0 0 1.5px $secondary-25;
        }
      }

      .carousel-inner {
        height: 100%;
        border-radius: 20px;
      }
    }
  }
}

.OfferDetailThumbnailFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9;
}

.OfferDetailThumbnail {
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.OfferDetailAction {
  position: relative;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  height: 3em;
  width: 100%;
}

.RewardCenterLinkAccount {
  width: 32%;
  height: 34%;
}

.RewardCenterPurchaseResponse {
  max-width: 500px;
  height: 75%;
  text-align: center;
  padding: 0 0.5em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0.5em;
  width: 100%;
}

.RewardCenterKycPopUp {
  position: absolute;
  border-radius: 2em;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 0.5em;
  z-index: 120;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 70%;
}

.RewardCenterPurchaseResponse,
.RewardCenterLinkAccount {
  position: absolute;
  border-radius: 2em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 0.5em;
  z-index: 120;
  display: flex;
  flex-direction: column;
}

.RewardCenterPurchaseResponse {
  top: 48%;
  height: 25vw;
}

.RewardCenterPurchaseResponseOverlay,
.RewardCenterLinkAccountOverlay {
  background: $common-36;
  width: 200%;
  position: absolute;
  height: 200%;
  z-index: 1;
  left: -50%;
  top: -50%;
}

.RewardCenterPurchaseResponseOverlay {
  background: $common-36;
  width: 60vw;
  position: absolute;
  height: 33vw;
  z-index: 120;
  left: 0%;
  top: 0%;
  margin-left: 0.3em;
  margin-top: 0.3em;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-height: 1800px) {
  .RewardCenterPurchaseResponseOverlay {
    width: 60vw;
    height: 33vw;
  }
  .RewardCenterPurchaseResponse {
    height: 23vw;
  }
}

@media only screen and (min-height: 1000px) {
  .RewardCenterPurchaseResponseOverlay {
    width: 65vw;
  }
  .RewardCenterPurchaseResponse {
    height: 23vw;
  }
}

@media only screen and (max-width: 1600px) {
  .RewardCenterPurchaseResponse {
    height: 30vw;
    width: 30vw;
    top: 50%;
  }
  .RewardCenterPurchaseResponseOverlay {
    width: 70vw;
    height: 37vw;
  }
}

@media only screen and (max-width: 1600px) and (min-height: 900px) {
  .RewardCenterPurchaseResponseOverlay {
    height: 39.5vw;
  }
  .RewardCenterPurchaseResponse {
    height: 28vw;
    width: 31vw;
  }
}

@media only screen and (max-width: 1600px) and (max-height: 900px) {
  .RewardCenterPurchaseResponseOverlay {
    height: 39.5vw;
    width: 70vw;
  }
  .RewardCenterPurchaseResponse {
    height: 31vw;
    width: 29vw;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 960px) {
  .RewardCenterPurchaseResponseOverlay {
    height: 39.5vw;
    width: 78vw;
  }
  .RewardCenterPurchaseResponse {
    height: 34vw;
    width: 37vw;
  }
}

@media only screen and (max-width: 1500px) and (min-width: 1440px) and (min-height: 800px) {
  .RewardCenterPurchaseResponseOverlay {
    height: 35.5vw;
    width: 66vw;
  }
}

@media only screen and (min-width: 1300px) and (max-height: 800px) {
  .RewardCenterPurchaseResponseOverlay {
    height: 37vw;
    width: 70vw;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1600px) and (min-height: 800px) and (max-height: 900px) {
  .RewardCenterPurchaseResponseOverlay {
    height: 41vw;
  }
}

.RewardCenterKycPopUpHeader {
  font-size: 2em;
  text-transform: uppercase;
  height: 14%;
  margin-top: 0.2em;
}

.RewardCenterKycPopUpMessage {
  margin-top: -1em;
  font-size: 1.4em;
}

.RewardCenterLinkAccountMessage {
  font-size: 1.4em;
  width: 95%;
  text-align: center;
}

.RewardCenterPurchaseResponseThumbnail {
  object-fit: contain;
  width: 50%;
  align-self: center;
  margin-top: 0.5em;
  font-size: 1.2em;
}

.RewardCenterPurchaseResponseAction {
  margin: 1em auto;
}

.RewardCenterKycAction {
  margin: 3em auto;
}

.RewardCenterLinkAccountAction {
  margin: 2em auto;
}

.RewardCenterPurchaseResponseAction,
.RewardCenterLinkAccountAction {
  position: relative;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  width: 10em;
  height: 14%;

  .RewardCenterLinkAccountActionLabel {
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 1em;
    font-weight: 500;
    text-shadow:
      1px 1px 0px $secondary-1,
      1px -1px 0px $secondary-1,
      -1px 1px 0px $secondary-1,
      -1px -1px 0px $secondary-1;
    transform: translate(-50%, -50%);
  }
}

.RewardCenterSideBar {
  padding-left: 3em;
  padding-bottom: 5em;
}

/* For HRI */

.OfferActionContainer {
  height: 4em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.OfferDetailActionHRI {
  width: 40%;
  position: relative;
}

.OfferDetailPropertySelector {
  width: 50%;
  color: white;
  &.disable {
    pointer-events: none;
  }
}

