@import '../../../../../shared/assets/style/theme.scss';

.RewardCenterHistoryDetail {
  width: 100%;
  height: 90%;
}

.RewardCenterHistoryDetailTitle {
  font-size: 2em;
  font-weight: 600;
  flex: 1;
}

.RewardCenterHistoryDetailSubtitle {
  color: $accent-color;
  font-weight: 500;
  margin-top: 0.4em;
  font-size: 1.2em;
  flex: 1;
}

.RewardCenterHistoryDetailBarcode {
  margin-top: 1em;
  min-height: 5em;
  flex: 1;
}

.RewardCenterHistoryDetailEmailAction {
  margin-top: 1em;
  position: relative;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  width: 23em;
  height: 3.5em;
  flex: 1;

  .SendEmailButtonLabel {
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 1em;
    font-weight: 500;
    text-shadow:
      1px 1px 0px $secondary-0,
      1px -1px 0px $secondary-0,
      -1px 1px 0px $secondary-0,
      -1px -1px 0px $secondary-0;
    transform: translate(-50%, -50%);
  }
}

.RewardCenterHistoryDetailDates {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.RewardCenterHistoryDetailTermsTitle {
  align-self: center;
  color: gray;
  font-size: 1.1em;
  margin-top: 0.6em;
  margin-bottom: 0.5em;
  flex: 1;
}

.RewardCenterHistoryDetailTerms {
  color: gray;
  text-align: center;
  font-size: 0.7em;
  line-height: 1.1em;
  flex: 1;
  width: 90%;
}

.RewardCenterHistoryDetailRemainingBalance {
  margin-bottom: 1rem;
  font-size: 1.6em;
  font-weight: '400';
}

.voucherText {
  position: relative;
}

