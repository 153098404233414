.vipAccessContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: stretch;
  justify-content: stretch;

  .vipAccessTitle {
    font-size: 2.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
  }

  .vipAccessInfo {
    font-size: 1.25rem;

    &.ieHack {
      height: 55%;
    }

    .vipAccessSubtitle,
    .vipPassSubtitle {
      position: relative;
      text-align: center;
      font-weight: 450;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      top: -15px;

      img {
        position: absolute;
        top: 1.25rem;
        left: 0;
        width: 100%;
      }
    }

    .vipPassSubtitle {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .vipAccessSteps {
      display: flex;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    .hasVipAccess {
      width: 100%;
      text-align: center;

      img {
        width: 80%;
      }
    }
  }

  .vipAccessButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1rem;

    &.ieHack {
      justify-content: space-around;
      height: 30%;
    }

    .vipAccessButton {
      width: 28%;
    }
  }

  .vipAccessButton {
    position: relative;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;

    img {
      width: 100%;
    }
  }
}

