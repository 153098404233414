@import '../../../../shared/assets/style/theme.scss';

.gtp-modal-cheatMenu {
  .modal-body {
    padding: 0;
  }

  .header {
    width: 100%;
    height: 3.7rem;
  }

  .headerImage {
    width: '100%';
    height: 3.7rem;
    object-fit: 'contain';
    z-index: 11;
    position: 'absolute';
    top: 0;
    left: 0;
  }

  .headerContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5vmin;
  }

  .headerTitle {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
    font-size: 1.9rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    height: 24rem;
  }

  .item {
    width: 90%;
    padding: 0.7rem 0px 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .itemContent {
    display: flex;
    flex-direction: column;
  }

  .itemLabel {
    @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
    text-shadow: 0px 1px 2px $common-26;
    font-size: 1.3rem;
    font-weight: 400;
    &:before {
      text-transform: capitalize;
    }
  }

  .itemButton {
    position: relative;
    height: 40px;
    width: 120px;

    span {
      @include linearGradientText($common-0, $common-1 20%, $common-32 100%);
      text-shadow: 0px 1px 2px $common-26;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .divider {
    width: 100%;
  }

  .testGameInputField {
    width: 100%;
    height: 42px;
    text-align: left;
    padding-left: 10px;
    box-shadow: none;
    border-radius: 0.3em;
    background-color: unset;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
  }
  .testGameInputField::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $common-18;
  }
  .testGameInputField::-moz-placeholder {
    /* Firefox 19+ */
    color: $common-18;
  }
  .testGameInputField:-ms-input-placeholder {
    /* IE 10+ */
    color: $common-18;
  }
  .testGameInputField:-moz-placeholder {
    /* Firefox 18- */
    color: $common-18;
  }
}
