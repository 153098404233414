@import '../../../../../../shared/assets/style/theme.scss';

.custom-scrollbar-profile-tab .os-scrollbar-track {
  background-color: $scrollbar-1;
  box-shadow: inset 0 0 4px 2px rgba(0, 0, 0, 0.5);
  width: 8px;
}

.custom-scrollbar-profile-tab .os-scrollbar-vertical .os-scrollbar-handle {
  background-color: $scrollbar-2;
  border-radius: 10px;
  width: 7px;
  margin-right: 1px;
}

.custom-scrollbar-profile-tab .os-scrollbar {
  margin: 10px 5px 10px 0;
  padding: 0;
}

.custom-scrollbar-profile-tab {
  height: 46vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

