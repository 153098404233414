.gtp-modal-kyc-lockout {
  .modal-body {
    padding: 0;
  }

  .header {
    position: relative;
    height: 62px;
  }

  .header-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 80px 60px 80px;

    img {
      width: 30%;
      margin-bottom: 40px;
    }

    p {
      text-align: center;
      margin-bottom: 50px;
      font-weight: 500;
      font-size: 17px;
    }
  }

  .button-container {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .button {
      width: 250px;
      height: 70px;
    }

    .button-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-text {
      @extend .gradientText;
      font-size: 1.7rem;
      font-weight: 600;
    }
  }
}
