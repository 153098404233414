@import '../../../../shared/assets/style/theme.scss';

.navigation-darker {
  position: absolute;
  width: 100%;

  img {
    width: 100%;
  }
}

.roomPage {
  .roomContent {

    .Overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $common-26;
      z-index: 3;
      padding: 10vh 20vw;
      color: $common-1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .Title {
        font-size: 4vh;
        height: 4vh;
      }

      .Container {
        width: 100%;
        height: 35vh;
        padding: 10vh;

        .Column {
          display: inline-block;
          width: 33%;
          height: 100%;

          .Icon {
            height: 100%;
            text-align: center;
          }
        }
      }

      .Button {
        position: relative;
        margin-top: 10vh;
        cursor: pointer;

        .ButtonText {
          position: absolute;
          top: 2rem;
          left: 0;
          font-size: 2rem;
          width: 100%;
          z-index: 9;
        }
      }
    }
  }
}
