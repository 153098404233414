.gtp-modal-favouritesUnlocked {
  width: 60vw;
  max-width: none;
  height: 90vh;

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .button {
    bottom: 13%;
    cursor: pointer;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    width: 25%;
  }

  .buttonImage {
    width: 100%;
    height: 100%;
  }
}
