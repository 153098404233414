.promo-link-applied {
  .modal-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 2% 0;
  }
}
