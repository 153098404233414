.gtp-modal-favouritesExpired {
  .header {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .messageContainer {
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  .message {
    font-size: 20px;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .button {
    position: relative;
    text-align: center;
    cursor: pointer;
    pointer-events: auto;
    width: 200px;
    height: 60px;
  }

  .buttonImage {
    width: 200px;
    height: 60px;
  }

  .buttonLabel {
    position: absolute;
    font-size: 1.4rem;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}
