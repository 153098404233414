@import '../../../../shared/assets/style/theme.scss';

.flexBarSection {
  .BarItem {
    position: relative;
    cursor: pointer;
    padding-bottom: 1rem;
    margin: 0;
    flex: 1;
    justify-content: center;
    align-self: flex-end;

    .videoAdsTimerLabel {
      font-weight: 600;
      margin-top: 0;
      font-size: 0.8rem;
    }
  }

  .BarItemIcon {
    height: 4.5em;
  }

  .AnimatedIcon {
    animation-duration: 500ms;
    animation-name: zoom;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @keyframes zoom {
      from {
        transform: scale(1) translate(0px, 0px);
      }

      to {
        transform: scale(1.25) translate(0px, -20px);
      }
    }
  }

  .LockIconContainer {
    height: 100%;
  }

  .LockIcon {
    top: -1%;
    height: 78%;
    z-index: 11;
    filter: drop-shadow(2px 4px 25px black);
  }

  .VIPIconContainer {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-bottom: 0.4em;
  }

  .VIPIcon {

    &.Locked {
      filter: brightness(0.5);
    }
  }

  .SlotIconContainer {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .SlotIcon {
    bottom: 0;
  }

  .BarItemLabel {
    text-transform: uppercase;
    color: $primary-23;
    font-weight: 600;
    margin-top: auto;
    font-size: 1rem;
  }

  .MainCTALabel {
    color: $common-1;
    z-index: 2;
    margin-top: -2.5em;
    font-size: 2em;
    font-weight: 300;
    text-align: center;
  }

  .TimedChest {
    bottom: 0;
    height: 4.5em;
    position: relative;
    top: -0.75rem;
    left: 0.45rem;
  }

  .TimerCollect {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    bottom: -0.5em;
  }

  .TimerWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    bottom: 0;
  }

  .TimerBackground {
    position: absolute;
    width: 100%;
    bottom: -0.5em;
  }

  .TimerLabel {
    position: absolute;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-left: 15%;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1;
    font-feature-settings: 'tnum';
    bottom: 10%;
    z-index: 2;
  }
}
