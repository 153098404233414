.gtp-modal-session-expired {
  .ModalBackground {
    display: none;
  }
  .sessionExpiredWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    height: 100%;
    margin: 0 10%;
  }
}