.gtp-modal-WelcomeBonus {
  width: 59vw !important;

  .modal-content {
    padding-top: 60.25% !important;
  }

  .ModalBackground {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0%;
  }

  .ModalBackground:before {
    content: none;
  }

  .offerDetails {
    padding: 0 10px;
    position: absolute;
    bottom: 18.4%;
    left: -1%;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    .coinContainer {
      margin-bottom: -0.5%;
    }

    .quickBuyCoinAmount {
      margin-left: -0.6em;
    }
  }
}
