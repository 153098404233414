@import '../../../../../shared/assets/style/theme.scss';

.RewardCenterHistory {
  width: 100%;
  height: 100%;
}

.HistoryHeadings {
  font-size: 1.2em;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3.5em;
  justify-content: space-evenly;
  align-items: center;
}

.HistoryHeading {
  &:first-child {
    width: 35%;
    text-align: center;
  }

  &.HistoryItemStatus,
  &.HistoryItemDate {
    width: 22%;
    text-align: center;
  }

  &.HistoryItemDetailsAction {
    width: 21%;
  }
}

.RewardCenterHistoryEmpty {
  display: flex;
  width: 100%;
  height: 90%;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.2em;
  font-style: italic;
  font-weight: 700;
}

.HistoryItem {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 3.5em;

  &.odd {
    background-color: $primary-5;
  }

  .HistoryItemOverview {
    width: 35%;
    text-align: center;
    justify-content: center;
  }

  .HistoryItemStatus,
  .HistoryItemDate {
    width: 22%;
    text-align: center;
  }

  .HistoryItemDetailsAction {
    width: 21%;
    text-align: center;
  }

  .HistoryItemOverview {
    display: flex;
  }
}

.HistoryItemStatus,
.HistoryItemDate {
  margin: 0;
  text-transform: capitalize;
}

.HistoryPreloadState {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.HistoryItemName {
  align-self: center;
  margin: 0px;
  width: 45%;
}

.HistoryItemStar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 3.5em;

  &.odd {
    background-color: $primary-5;
  }

  .HistoryItemOverview {
    width: 32%;
    text-align: center;
    justify-content: center;
  }

  .HistoryItemStatus {
    width: 15%;
    text-align: center;
  }

  .HistoryItemDate {
    width: 30%;
    text-align: center;
  }

  .HistoryItemDetailsAction {
    width: 22%;
    text-align: center;
  }

  .HistoryItemRemaining {
    width: 10%;
    text-align: center;
  }

  .HistoryItemOverview {
    display: flex;
  }
}

.HistoryHeadingStar {
  display: flex;
  justify-content: center;

  &.HistoryItemOverview {
    width: 27%;
  }

  &.HistoryItemStatus {
    width: 10%;
  }
  &.HistoryItemDate {
    width: 20%;
  }

  &.HistoryItemDetailsAction {
    width: 10%;
  }

  &.HistoryItemRemaining {
    width: 10%;
  }

  &.HistoryItemOverviewWithButton {
    width: 22%;
  }
}

