.gtp-modal-favouriteHelper {

  .modal-content {
    padding: 0.1rem 2rem 0.1rem 2rem;
    border-radius: 2rem;
    width: 80%;
    height: 100%;
    }

    .helperBackground {
      height: 100%; 
      width: 100%;
    }

    .headerStyle {
      height: 40%; 
      width: 100%;
      text-align: center; 
      position: absolute;
      margin-left: -15px
    }

    .dialogueButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin: 1em 0;
    }
  
    .dialogueButtons{
      position: relative;
      text-align: center;
      cursor: pointer;
      pointer-events: auto;
  
      .leftButtonLabel, .rightButtonLabel {
        position: absolute;
        font-size: 1.4rem;
        text-align: center;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
